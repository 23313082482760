import { createContext } from 'react'

const customer = {
  isAuthenticatedByPartner: false,
  customerId: '',
  customerToken: '',
  document: '',
  fullname: '',
  birthDate: '',
  motherName: '',
  maritalStatus: '',
  phoneNumber: '',
  email: '',
  profession: '',
  rg: {
    number: '',
    issuingDate: '',
    issuingBody: '',
  },
  birthCity: '',
  birthState: '',
  birthCountry: '',
  nationality: '',
  address: {
    streetAddress: '',
    streetNumber: '',
    complement: '',
    zipcode: '',
    neighborhood: '',
    city: '',
    state: '',
  },
  bankInformation: {
    id: '',
    agency: {
      number: '',
      checkDigit: '',
    },
    account: {
      number: '',
      checkDigit: '',
    },
  },
}

const simulation = {
  requestedLoanAmount: '',
  term: '',
  maximumRequestableLoanAmount: '',
  iofAmount: '',
  apRate: {
    monthly: '',
    yearly: '',
  },
  cetRate: {
    monthly: '',
    yearly: '',
  },
  installmentAmount: '',
  certificates: [
    {
      certificateId: '',
      plan: '',
      fundName: '',
      reservedAmount: '',
    },
  ],
  loanAmountTotal: '',
  loanAvailableAmount: '',
  withdrawalAmountTotal: '',
  customerSavingAmount: '',
  reservedAmountTotal: '',
}

export const initialState = {
  ...customer,
  ...simulation,
}

export const ApplicationContext = createContext({
  state: initialState,
  dispatcher: null,
})
