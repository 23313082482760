export function toCurrency({ number = 12.34 }) {
  const [whole, fraction] = number.toString().split('.')

  return `${whole
    .toString()
    .split('')
    .reverse()
    .reduce((acc, digit, index) => {
      acc += index != 0 && index % 3 == 0 ? `.${digit}` : digit
      return acc
    }, '')
    .split('')
    .reverse()
    .join('')},${`${fraction || ''}00`.substring(0, 2)}`.trim()
}

export function toPercent({ number = 0 }) {
  return (number * 100).toFixed(2)
}

export function toSlashDate({ date = '2000-01-01' }) {
  const [year, month, day] = date.split('-')

  return `${day}/${month}/${year}`
}

export function toIsoDate({ date = '01/01/2000' }) {
  const [day, month, year] = date.split('/')

  return `${year}-${month}-${day}`
}

export function toCpfMask({ document = '12345678900' }) {
  return document.split('').reduce((acc, digit, index) => {
    if ([3, 6].includes(index)) acc += `.${digit}`
    else if ([9].includes(index)) acc += `-${digit}`
    else acc += digit
    return acc
  }, '')
}
