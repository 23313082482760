// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-onboarding-js": () => import("./../src/pages/Onboarding.js" /* webpackChunkName: "component---src-pages-onboarding-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/Login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-simulation-js": () => import("./../src/pages/Simulation.js" /* webpackChunkName: "component---src-pages-simulation-js" */),
  "component---src-pages-customer-js": () => import("./../src/pages/Customer.js" /* webpackChunkName: "component---src-pages-customer-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-playground-js": () => import("./../src/pages/playground.js" /* webpackChunkName: "component---src-pages-playground-js" */)
}

