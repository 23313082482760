import React, { useReducer } from 'react'
import { node } from 'prop-types'
import { toCurrency, toPercent } from 'utils/formatters'
import { ApplicationContext, initialState } from './ApplicationContext'

const propTypes = {
  children: node,
}

function customerUpdate(state, payload) {
  return { ...state, ...payload }
}

function maximumRequestableLoanAmountUpdate(state, payload) {
  return {
    ...state,
    maximumRequestableLoanAmount: Number(payload.maximumRequestableLoanAmount),
  }
}

function simulationReset(state) {
  return {
    ...state,
    iofAmount: '',
    apRate: {
      monthly: '',
      yearly: '',
    },
    cetRate: {
      monthly: '',
      yearly: '',
    },
    installmentAmount: '',
    certificates: [
      {
        certificateId: '',
        plan: '',
        fundName: '',
        reservedAmount: '',
      },
    ],
    loanAmountTotal: '',
    loanAvailableAmount: '',
    withdrawalAmountTotal: '',
    customerSavingAmount: '',
    reservedAmountTotal: '',
  }
}

function simulationUpdate(state, payload) {
  const certificates = payload.certificates.map(certificate => ({
    certificateId: certificate.id,
    plan: certificate.plan,
    fundName: certificate.fund.name,
    reservedAmount: toCurrency({ number: certificate.reservedAmount }),
  }))

  return {
    ...state,
    ...payload,
    apRate: {
      monthly: toPercent({ number: payload.apRate.monthly }),
      yearly: toPercent({ number: payload.apRate.yearly }),
    },
    cetRate: {
      monthly: toPercent({ number: payload.cetRate.monthly }),
      yearly: toPercent({ number: payload.cetRate.yearly }),
    },
    iofAmount: toCurrency({ number: payload.iofAmount }),
    installmentAmount: toCurrency({ number: payload.installmentAmount }),
    loanAmountTotal: toCurrency({
      number: payload.loanAmountTotal,
    }),
    loanAvailableAmount: toCurrency({ number: payload.loanAvailableAmount }),
    withdrawalAmountTotal: toCurrency({
      number: payload.withdrawalAmountTotal,
    }),
    customerSavingAmount: toCurrency({ number: payload.customerSavingAmount }),
    reservedAmountTotal: toCurrency({ number: payload.reservedAmountTotal }),
    certificates,
  }
}

function simulationConditionsUpdate(state, payload) {
  return {
    ...state,
    ...payload,
    requestedLoanAmount: payload.requestedLoanAmount,
  }
}

function authenticateByPartnerUpdate(state, payload) {
  return { ...state, ...payload }
}

function authenticateUpdate(state, payload) {
  return { ...state, ...payload }
}

function resetAll(state, payload) {
  return { ...state, ...payload, ...initialState }
}

function logReducer(state, action) {
  // eslint-disable-next-line
  console.log(`State: ${JSON.stringify(state, null, 2)}`)
  // eslint-disable-next-line
  console.log(`Action: ${JSON.stringify(action, null, 2)}`)
}

function reducer(state, action) {
  // eslint-disable-next-line
  process.env.GATSBY_ACTIVE_ENV == 'development'
    ? logReducer(state, action)
    : null

  switch (action.type) {
    case 'authenticateByPartner':
      return authenticateByPartnerUpdate(state, action.payload)

    case 'authenticate':
      return authenticateUpdate(state, action.payload)

    case 'customer':
      return customerUpdate(state, action.payload)

    case 'maximumRequestableLoanAmount':
      return maximumRequestableLoanAmountUpdate(state, action.payload)

    case 'simulationConditionsUpdate':
      return simulationConditionsUpdate(state, action.payload)

    case 'simulationUpdate':
      return simulationUpdate(state, action.payload)

    case 'simulationReset':
      return simulationReset(state)

    case 'reset':
      return resetAll(state, action.payload)

    default:
      throw new Error('Action type not yet implemented')
  }
}
function ApplicationProvider({ children }) {
  const [state, dispatcher] = useReducer(reducer, initialState)

  return (
    <ApplicationContext.Provider value={{ state, dispatcher }}>
      {children}
    </ApplicationContext.Provider>
  )
}

ApplicationProvider.propTypes = propTypes
ApplicationProvider.displayName = 'ApplicationProvider'

export { ApplicationProvider }
